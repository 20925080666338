<template>
    <div
        class="home"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">找回密码</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <img
                            class="top-icon-img"
                            :src="$img.lock"
                        />
                        <p class="accont-slogin">找回密码</p>
                    </div>
                </div>
                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :model="accountForm"
                        class="g-form"
                        style="width:370px;"
                    >
                        <el-form-item prop="phone">
                            <el-input
                                disabled
                                type="tel"
                                vlule="13330510176"
                                v-model="accountForm.phone"
                                placeholder="13330510179"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop="text"
                            class="yzm-item"
                        >
                            <el-input
                                type="text"
                                v-model="accountForm.code"
                                placeholder="请输入验证码"
                                auto-complete="off"
                            ></el-input>
                            <el-button class="dx-code">发送验证码</el-button>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input
                                type="password"
                                v-model="accountForm.newpassword"
                                placeholder="请输入新密码"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input
                                type="password"
                                v-model="accountForm.newpassword"
                                placeholder="请确认新密码"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <el-form-item class="btn-wrap ml-b">
                            <div class="btn-cont">
                                <el-button type="primary">确认修改</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item class="btn-wrap back-wrap mr-b">
                            <div class="btn-cont">
                                <el-button type="primary">返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "validEmail",
    metaInfo() {
        return {
            title: "重置密码 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            accountForm: {
                validpassword: "" //密码
            },
            rules: {
                password: [
                    {
                        required: true,
                        message: "请输入登录密码",
                        trigger: ["change", "blur"]
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //获取邮箱验证码
        getMobileCode() {
            let _this = this;
            _this.$refs["accountForm"].validateField("validpassword", function (
                val,
                error
            ) {
                if (!val) {
                    _this.loading = true;
                    _this.post(
                        "/user/user/getcode",
                        _this.accountForm,
                        data => {
                            _this.loading = false;
                            if (data.code == 200) {
                            } else {
                                _this.$message.error(data.msg);
                                return;
                            }
                        }
                    );
                }
            });
        }
    },
    created() { }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


